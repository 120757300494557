.reactMarkDown {
  font-family: Poppins, sans-serif;
  color: #2e4044;
  overflow-wrap: break-word;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 8px;
  padding-top: 8px;
}

.reactMarkDown a {
  color: #09798b;
  font-weight: 500;
  text-decoration: underline;
}

.reactMarkDown p {
  margin-bottom: 14px;
}

.reactMarkDown h1 {
  font-weight: 600;
  font-size: 16px;
}

.reactMarkDown h2 {
  font-weight: 500;
}

.reactMarkDown h3 {
  font-weight: 500px;
}

.reactMarkDown h4 {
  font-weight: 500;
}

.reactMarkDown ul {
  margin-top: 14px;
  margin-bottom: 14px;
  list-style: disc outside none;
}

.reactMarkDown ul li {
  margin-left: 2em;
  display: list-item;
  text-align: -webkit-match-parent;
  margin-bottom: 16px;
}

.reactMarkDown ol {
  margin-top: 14px;
  margin-bottom: 14px;
  list-style: decimal outside none;
}

.reactMarkDown ol li {
  margin-left: 2em;
  display: list-item;
  text-align: -webkit-match-parent;
  margin-bottom: 16px;
}
