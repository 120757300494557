@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-light: 255 255 255;
  --primary-dark: 38 38 65;

  --brand-50: 230 242 243;
  --brand-100: 179 213 219;
  --brand-200: 142 193 202;
  --brand-300: 90 165 177;
  --brand-400: 58 148 162;
  --brand-500: 9 121 139;
  --brand-600: 8 110 126;
  --brand-700: 6 86 99;
  --brand-800: 5 67 76;
  --brand-900: 4 51 58;

  --alt-brand-50: 237 237 253;
  --alt-brand-100: 199 200 249;
  --alt-brand-200: 172 173 247;
  --alt-brand-300: 134 136 243;
  --alt-brand-400: 111 113 241;
  --alt-brand-500: 75 77 237;
  --alt-brand-600: 68 70 216;
  --alt-brand-700: 53 55 168;
  --alt-brand-800: 41 42 130;
  --alt-brand-900: 32 32 100;

  --orange-50: 253 243 233;
  --orange-100: 249 216 186;
  --orange-200: 246 198 152;
  --orange-300: 242 171 106;
  --orange-400: 240 155 77;
  --orange-500: 236 130 32;
  --orange-600: 215 118 29;
  --orange-700: 168 92 23;
  --orange-800: 130 72 18;
  --orange-900: 99 55 13;

  --neutral-50: 242 242 244;
  --neutral-100: 215 215 220;
  --neutral-200: 195 195 203;
  --neutral-300: 168 168 179;
  --neutral-400: 151 151 164;
  --neutral-500: 125 125 141;
  --neutral-600: 114 114 128;
  --neutral-700: 89 89 100;
  --neutral-800: 69 69 78;
  --neutral-900: 53 53 59;

  --green-50: 234 250 247;
  --green-100: 191 240 229;
  --green-200: 160 233 216;
  --green-300: 117 224 198;
  --green-400: 90 217 187;
  --green-500: 49 208 170;
  --green-600: 45 189 155;
  --green-700: 35 148 121;
  --green-800: 27 114 94;
  --green-900: 21 87 71;

  --warm-50: 253 235 231;
  --warm-100: 248 194 182;
  --warm-200: 244 164 146;
  --warm-300: 240 122 96;
  --warm-400: 237 97 65;
  --warm-500: 232 57 18;
  --warm-600: 211 52 16;
  --warm-700: 165 40 13;
  --warm-800: 128 31 10;
  --warm-900: 97 24 8;

  --header-height: 84px;
  --header-icon-height: 42px;
  --header-search-height: 132px;
  --space-after-header: 16px;
}

@media (prefers-color-scheme: dark) {
  body {
    --primary-light: 255 255 255;
    --primary-dark: 38 38 65;

    --brand-50: 230 242 243;
    --brand-100: 179 213 219;
    --brand-200: 142 193 202;
    --brand-300: 90 165 177;
    --brand-400: 58 148 162;
    --brand-500: 9 121 139;
    --brand-600: 8 110 126;
    --brand-700: 6 86 99;
    --brand-800: 5 67 76;
    --brand-900: 4 51 58;

    --alt-brand-50: 237 237 253;
    --alt-brand-100: 199 200 249;
    --alt-brand-200: 172 173 247;
    --alt-brand-300: 134 136 243;
    --alt-brand-400: 111 113 241;
    --alt-brand-500: 75 77 237;
    --alt-brand-600: 68 70 216;
    --alt-brand-700: 53 55 168;
    --alt-brand-800: 41 42 130;
    --alt-brand-900: 32 32 100;

    --orange-50: 253 243 233;
    --orange-100: 249 216 186;
    --orange-200: 246 198 152;
    --orange-300: 242 171 106;
    --orange-400: 240 155 77;
    --orange-500: 236 130 32;
    --orange-600: 215 118 29;
    --orange-700: 168 92 23;
    --orange-800: 130 72 18;
    --orange-900: 99 55 13;

    --neutral-50: 242 242 244;
    --neutral-100: 215 215 220;
    --neutral-200: 195 195 203;
    --neutral-300: 168 168 179;
    --neutral-400: 151 151 164;
    --neutral-500: 125 125 141;
    --neutral-600: 114 114 128;
    --neutral-700: 89 89 100;
    --neutral-800: 69 69 78;
    --neutral-900: 53 53 59;

    --green-50: 234 250 247;
    --green-100: 191 240 229;
    --green-200: 160 233 216;
    --green-300: 117 224 198;
    --green-400: 90 217 187;
    --green-500: 49 208 170;
    --green-600: 45 189 155;
    --green-700: 35 148 121;
    --green-800: 27 114 94;
    --green-900: 21 87 71;

    --warm-50: 253 235 231;
    --warm-100: 248 194 182;
    --warm-200: 244 164 146;
    --warm-300: 240 122 96;
    --warm-400: 237 97 65;
    --warm-500: 232 57 18;
    --warm-600: 211 52 16;
    --warm-700: 165 40 13;
    --warm-800: 128 31 10;
    --warm-900: 97 24 8;

    --header-height: 84px;
    --header-icon-height: 42px;
    --header-search-height: 132px;
    --space-after-header: 16px;
  }
}

@layer components {
  .text-display {
    @apply text-28 leading-9 lg:text-40 lg:leading-13;
  }

  .text-heading {
    @apply text-24 leading-8 lg:text-32 lg:leading-10;
  }

  .text-title {
    @apply text-20 leading-6 lg:text-25 lg:leading-8;
  }

  .text-body-primary {
    @apply text-16 leading-5 lg:text-20 lg:leading-6;
  }

  .text-body-secondary {
    @apply text-14 leading-5 lg:text-16 lg:leading-6;
  }

  .text-caption-primary {
    @apply text-12 leading-4 lg:text-14 lg:leading-5;
  }

  .text-button-large {
    @apply text-16 leading-7 tracking-[0.64px] lg:text-16 lg:leading-7;
  }

  .text-button-medium {
    @apply text-14 leading-6 tracking-[0.54px] lg:text-14 lg:leading-6;
  }

  .text-button-small {
    @apply text-10 leading-5 tracking-wide lg:text-10 lg:leading-5;
  }

  .hide-scrollbar {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }
  .hide-scrollbar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

[vaul-drawer][vaul-drawer-direction='bottom']:after {
  top: 100%;
  bottom: initial;
  left: 0;
  right: 0;
  height: 0% !important;
}

html {
  height: -webkit-fill-available;
}
